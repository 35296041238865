import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  const testimonials = ['luxmeyer', 'anwaltverlag', 'limmaland', 'pluspulso', 'dakonix', 'mcfoxx', 'impactandemotions']

  return (
  <Layout>
    <SEO
      title="Kunden Online-Marketing & Webentwicklung - Projectival"
      description="► Das ist eine Auswahl an Kunden, mit denen ich in der letzten Zeit tolle Projekte umgesetzt habe ► Sie wollen auch, dass ich Sie tatkräftig unterstütze?"
    />
  	<Container>
  		<Container.Inner>
		    <Title as="h1">Kunden</Title>
        <Content big>
          <p>Das ist eine Auswahl an Kunden, mit denen ich in der letzten Zeit tolle Projekte umgesetzt habe:</p>
        </Content>
		  </Container.Inner>
	  </Container>
    {testimonials.map((testimonial, testimonialIndex) =>
      <Container key={testimonialIndex} id={testimonial}>
        <Container.Inner>
          <Quote reverse={testimonialIndex % 2 == 0} {...data.allTestimonialsYaml.edges.find(el => el.node.name === testimonial).node} />
        </Container.Inner>
      </Container>
    )}
    <Contact>
      <Title center>Sie wollen auch, dass ich Sie tatkräftig unterstütze?</Title>
      <Content big center>
        <p>Egal ob die Lösung Ihres Problems unter Leistungen aufgeführt ist oder nicht, nehmen Sie gleich Kontakt zu mir auf und sagen Sie mir, wie ich Ihnen helfen kann.</p>
        <Button as={Link} primary="true" to="/kontakt/">Kontakt aufnehmen</Button><br />
        <Button as={Link} to="/leistungen/">Leistungen ansehen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: ["luxmeyer", "anwaltverlag", "limmaland", "pluspulso", "dakonix", "mcfoxx", "impactandemotions"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
  }
`
